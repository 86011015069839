import * as firebase from "firebase/app";
import "firebase/auth";

const app = firebase.initializeApp({
    apiKey: "AIzaSyBjRvH7z6MzV3wyd-0WAXMHEVVM5Y1Y5NQ",
    authDomain: "culturefund-d3208.firebaseapp.com",
    databaseURL: "https://culturefund-d3208.firebaseio.com",
    projectId: "culturefund-d3208",
    storageBucket: "culturefund-d3208.appspot.com",
    messagingSenderId: "209712052909",
    appId: "1:209712052909:web:bb5ceace565495c3c5a424",
    measurementId: "G-P027BT15MW"
});

firebase.analytics().logEvent('notification_received');

export default app;
